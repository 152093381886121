import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { breakpointAndUp, colors, typography } from 'assets/styles';
import { Profile, ChevronDown } from 'assets/icons';

export const Container = styled.button`
  ${typography.h5};
  min-width: 0;
  max-width: 100%;
  background-color: transparent;
  border: none;
  border-radius: 1.8rem;
  cursor: pointer;
  transition: all 200ms ease-in-out;

  line-height: 2rem;
  color: ${({ $isDark }) => ($isDark ? colors.white : colors.text)};
  text-decoration: none;

  @media (any-hover: hover) {
    &:hover {
      ${({ $isDark }) =>
        $isDark
          ? css`
              background-color: ${rgba(colors.white, 0.05)};
            `
          : css`
              opacity: 0.85;
            `}
    }
  }

  @media (${breakpointAndUp.tablet}) {
    display: inline-flex;
    align-items: center;
    padding: ${({ $hasImage }) => ($hasImage ? '0.6rem 1.6rem 0.6rem 0.8rem' : '0.8rem 1.6rem')};
    background-color: ${({ $isDark }) => ($isDark ? rgba(colors.white, 0.15) : colors.lightOrange)};
  }

  ${({ $labelOnMobile }) =>
    $labelOnMobile &&
    css`
      display: inline-flex;
      align-items: center;
      padding: 0.8rem 1.6rem;
      background-color: ${({ $isDark }) => ($isDark ? rgba(colors.white, 0.15) : colors.lightOrange)};
    `};

  ${({ $hasAlert }) =>
    $hasAlert &&
    css`
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 1.4rem;
        width: 1.4rem;
        border-radius: 50%;
        background-color: ${colors.tint};
      }
    `};
`;

export const AccountImage = styled.img`
  display: inline-block;
  height: 2.4rem;
  width: 2.4rem;
  border-radius: 50%;

  @media (${breakpointAndUp.tablet}) {
    margin-right: 0.8rem;
  }
`;

export const Label = styled.span`
  display: ${({ $showOnMobile }) => ($showOnMobile ? 'inline' : 'none')};

  @media (${breakpointAndUp.tablet}) {
    display: block;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const AccountIcon = styled(Profile)`
  margin-right: 0.8rem;
  font-size: ${({ $isInButton }) => ($isInButton ? '1.6rem' : '2.4rem')};
  color: ${({ $isInButton }) => $isInButton && colors.tint};

  @media (${breakpointAndUp.tablet}) {
    color: ${colors.tint};
    font-size: 1.6rem;
  }
`;

export const ChevronDownIcon = styled(ChevronDown)`
  display: none;
  margin-left: 0.8rem;
  font-size: 1.6rem;

  @media (${breakpointAndUp.tablet}) {
    display: inline;
  }
`;
