import React, { ComponentProps } from 'react';
import { Container, AccountImage, Label, AccountIcon, ChevronDownIcon } from './account-pill.style';
import { Image } from 'models/image';

export type AccountPillProps = ComponentProps<'span'> & {
  label: string;
  image?: Image;
  isDark?: boolean;
  hasAlert?: boolean;
  showLabelOnMobile?: boolean;
  href?: string;
};

const AccountPill: React.FC<AccountPillProps> = ({
  label,
  isDark,
  hasAlert,
  showLabelOnMobile,
  image,
  onClick,
  href,
}) => (
  <Container
    $isDark={isDark}
    $hasAlert={hasAlert}
    $hasImage={!!image}
    $labelOnMobile={showLabelOnMobile}
    onClick={href ? undefined : onClick}
    href={href}
    as={href ? 'a' : undefined}
  >
    {image ? <AccountImage src={image.src} alt={image.alt} /> : <AccountIcon $isInButton={showLabelOnMobile} />}
    <Label $showOnMobile={showLabelOnMobile}>{label}</Label>
    {!href && <ChevronDownIcon />}
  </Container>
);

export default AccountPill;
