import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { colors, typography } from 'assets/styles';

const activeStyle = css`
  background: ${rgba(colors.tint, 0.1)};
  color: ${colors.tint};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0.4rem;
    background-color: ${colors.tint};
  }
`;

export const Container = styled.div`
  position: relative;
`;

export const Item = styled.a`
  position: relative;
  display: block;
  text-align: left;
  padding: 0.8rem 1.2rem 0.8rem 4rem;

  background: transparent;
  border: none;
  text-decoration: none;
  cursor: pointer;

  color: inherit;

  ${({ $isActive }) => $isActive && activeStyle};

  @media (any-hover: hover) {
    &:hover {
      ${activeStyle};
    }
  }
`;

export const IconContainer = styled.span`
  position: absolute;
  top: 50%;
  left: 1.6rem;
  transform: translateY(-50%);
  font-size: 1.6rem;
`;

export const Label = styled.span`
  ${typography.h5};
`;

export const List = styled.ul`
  position: relative;
  margin-left: 2.4rem;
  margin-bottom: 2.4rem;

  ${({ $isOpen }) =>
    !$isOpen &&
    css`
      display: none;
    `};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0.2rem;
    background-color: ${({ $isDark, $isTransparent }) =>
      $isDark || $isTransparent ? rgba(colors.white, 0.18) : rgba(colors.darkGrey, 0.15)};
  }
`;

export const ListItem = styled.li`
  padding-top: 0.4rem;
`;
