import { Gift, Heart, History, Language, Map, Meditate, Music, Profile, Programs, Video } from 'assets/icons';
import { LOCALES } from 'config/constants';
import { MenuItem, MenuItemPage } from 'models/menu-item';
import React from 'react';
import { MenuLinks } from './links';

export const forYou: MenuItem = {
  label: 'menu.item.for-you.title',
  icon: <Profile />,
  url: MenuLinks.ForYou,
  notPremiumAllowed: true,
  premiumAllowed: false,
};

export const forYouPremium: MenuItem = {
  ...forYou,
  label: 'menu.item.for-you.premium.title',
  notPremiumAllowed: false,
  premiumAllowed: true,
};

export const meditation: MenuItem = {
  label: 'menu.item.meditate.title',
  icon: <Meditate />,
  url: MenuLinks.Meditations,
  activeOptions: [MenuLinks.Meditations, MenuLinks.Moments],
  notPremiumAllowed: true,
  premiumAllowed: true,
};

export const music: MenuItem = {
  label: 'menu.item.music.title',
  icon: <Music />,
  url: MenuLinks.Music,
  notPremiumAllowed: true,
  premiumAllowed: true,
};

export const programs: MenuItem = {
  label: 'menu.item.programs.title',
  icon: <Programs />,
  url: MenuLinks.Programs,
  notPremiumAllowed: true,
  premiumAllowed: true,
};

export const favorites: MenuItem = {
  label: 'menu.item.favorites.title',
  icon: <Heart />,
  url: MenuLinks.Favorites,
  notPremiumAllowed: true,
  premiumAllowed: true,
};

export const flow: MenuItem = {
  label: 'menu.item.yoga-flow.flow.title',
  icon: <Video />,
  url: MenuLinks.Flow,
  displayOnLocales: [LOCALES.NL],
  notPremiumAllowed: true,
  premiumAllowed: true,
};

export const yoga: MenuItem = {
  label: 'menu.item.yoga-flow.yoga.title',
  icon: <Video />,
  url: MenuLinks.Yoga,
  displayOnLocales: [LOCALES.NL],
  notPremiumAllowed: true,
  premiumAllowed: true,
};

export const favoriteMeditations: MenuItem = {
  label: 'favorite.tabs.meditation.title',
  icon: <Meditate />,
  url: MenuLinks.Favorites,
};

export const favoriteMusic: MenuItem = {
  label: 'favorite.tabs.music.title',
  icon: <Music />,
  url: MenuLinks.FavoritesMusic,
};

export const recentMeditations: MenuItem = {
  label: 'profile.recently-played.tabs.meditation.title',
  icon: <Meditate />,
  url: MenuLinks.ProfileRecentlyPlayed,
};

export const recentMusic: MenuItem = {
  label: 'profile.recently-played.tabs.music.title',
  icon: <Music />,
  url: MenuLinks.ProfileRecentlyPlayedMusic,
};

export const profile: MenuItem = {
  label: 'menu.item.profile-popup.profile.label',
  icon: <Profile />,
  url: MenuLinks.Profile,
  premiumAllowed: true,
  notPremiumAllowed: true,
};

export const profileSubscriptionPremium: MenuItem = {
  label: 'menu.item.profile-popup.subscription.premium.label',
  icon: <Meditate />,
  page: MenuItemPage.Subscription,
  external: true,
  premiumAllowed: true,
  notPremiumAllowed: false,
};

export const profileSubscription: MenuItem = {
  label: 'menu.item.profile-popup.subscription.label',
  icon: <Meditate />,
  page: MenuItemPage.Subscribe,
  external: true,
  premiumAllowed: false,
  notPremiumAllowed: true,
};

export const profileRecently: MenuItem = {
  label: 'menu.item.profile.recently-played.label',
  icon: <History />,
  url: MenuLinks.ProfileRecentlyPlayed,
  premiumAllowed: true,
};

export const profileLanguage: MenuItem = {
  label: 'menu.item.profile.language.label',
  icon: <Language />,
  url: MenuLinks.ProfileLanguage,
  premiumAllowed: true,
};

export const profileTimezone: MenuItem = {
  label: 'menu.item.profile.time-zone.label',
  icon: <Map />,
  url: MenuLinks.ProfileTimeZone,
  premiumAllowed: true,
};

export const profileGiftVoucher: MenuItem = {
  label: 'menu.item.profile.gift-voucher.label',
  icon: <Gift />,
  url: MenuLinks.ProfileGiftVoucher,
};
