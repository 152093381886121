import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { breakpointAndUp, colors, index, IndexOptions } from 'assets/styles';

export const HeaderContainer = styled.div`
  display: ${({ $hasTabletHeader }) => !$hasTabletHeader && 'none'};
  @media (${breakpointAndUp.tabletLarge}) {
    display: none;
  }
`;

export const NavigationContainer = styled.div`
  display: none;
  height: 100vh;

  @media (${breakpointAndUp.tablet}) {
    display: block;
    position: fixed;
    top: 0;
    right: 150vw;
    bottom: 0;
    left: -150vw;
    z-index: ${index(IndexOptions.NAVIGATION)};

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: background-color 200ms ease-in-out;
    }

    ${({ $isOpen }) =>
      $isOpen &&
      css`
        left: 0;
        right: 0;

        &::before {
          background-color: ${rgba(colors.black, 0.4)};
        }
      `}
  }

  @media (${breakpointAndUp.tabletLarge}) {
    position: sticky;
    left: 0;
    right: 0;

    &::before {
      content: none;
    }
  }
`;

export const MenuContainer = styled.div`
  position: absolute;
  top: 0;
  left: ${({ $isOpen }) => ($isOpen ? 0 : '-21rem')};
  height: 100%;
  width: 20.8rem;
  transition: left 200ms ease-in-out;

  @media (${breakpointAndUp.tabletLarge}) {
    position: relative;
    width: 100%;
    left: 0;
  }
`;
