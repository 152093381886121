import { useIntl } from 'domains/intl';
import { Selectors as userSelectors } from 'domains/user';
import Navigation from 'layouts/navigation';
import { useMusicPlayer } from 'logic-domains/music-player';
import { PlayerStates } from 'logic-domains/music-player/models';
import { DeviceImageSet } from 'models/image';
import { MenuItem as MenuItemType } from 'models/menu-item';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { ComponentProps, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  Content,
  ContentContainer,
  MobileNavigationContainer,
  NavigationContainer,
  ThemeOverlay,
} from './page-layout.style';

const BackgroundImage = dynamic(import('components/background-image'));
const MobileNavigation = dynamic(import('components/mobile-navigation'));

export type PageLayoutProps = ComponentProps<'main'> & {
  menuItems: MenuItemType[];
  mobileMenuItems?: MenuItemType[];
  activeUrl: string;
  backgroundImage?: DeviceImageSet;
  backgroundColor?: string;
  hasGradientBackground?: boolean;
  isDark?: boolean;
  hasTabletHeader?: boolean;
  hasTransparentNavigation?: boolean;
};

const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  menuItems,
  mobileMenuItems,
  activeUrl,
  backgroundImage,
  backgroundColor,
  hasGradientBackground,
  isDark,
  hasTabletHeader,
  hasTransparentNavigation,
}) => {
  const user = useSelector(userSelectors.getUser);
  const { locale } = useRouter();
  const { formatMessage } = useIntl();
  const { playerState } = useMusicPlayer();
  const _menuItems = useMemo(
    () =>
      menuItems.map((item) => ({
        ...item,
        label: formatMessage(item.label),
      })),
    [formatMessage, menuItems],
  );
  const _mobileMenuItems = useMemo(
    () =>
      mobileMenuItems?.map((item) => ({
        ...item,
        label: formatMessage(item.label),
      })),
    [formatMessage, mobileMenuItems],
  );

  return (
    <Container
      $image={backgroundImage}
      $backgroundColor={backgroundColor}
      $hasGradient={hasGradientBackground}
      $isDark={isDark}
    >
      {backgroundColor && !hasGradientBackground && <ThemeOverlay $backgroundColor={backgroundColor} />}
      {backgroundImage && hasGradientBackground && <BackgroundImage image={backgroundImage} />}
      <NavigationContainer>
        <Navigation
          menuItems={_menuItems}
          isDark={isDark}
          activeUrl={activeUrl}
          isTransparent={hasTransparentNavigation}
          hasTabletHeader={hasTabletHeader}
        />
      </NavigationContainer>
      <ContentContainer>
        <Content
          $hasBottomSpacing={!!_mobileMenuItems?.length}
          $hasMusicSpacing={playerState === PlayerStates.Minimized}
        >
          {children}
        </Content>
      </ContentContainer>
      {_mobileMenuItems && (
        <MobileNavigationContainer>
          <MobileNavigation
            premium={!!user}
            locale={locale}
            menuItems={_mobileMenuItems}
            isDark={isDark}
            activeUrl={activeUrl}
          />
        </MobileNavigationContainer>
      )}
    </Container>
  );
};

export default PageLayout;
