import React, { ComponentProps, useCallback, useRef, useState } from 'react';
import { MenuItem } from 'models/menu-item';
import { User } from 'models/user';
import AccountPill from 'components/account-pill';
import ProfilePopup from 'components/profile-popup';
import Button from 'components/button';
import useClickOutside from 'utils/hooks/use-click-outside';
import {
  Container,
  AccountPillContainer,
  ButtonContainer,
  PopupLayout,
  PopupContainer,
} from './header-account-buttons.style';

export type HeaderAccountButtonsProps = ComponentProps<'div'> & {
  isDark?: boolean;
  user?: User;
  loginLabel: string;
  loginLink: string;
  logoutLabel: string;
  logoutLink: string;
  profileLinks: MenuItem[];
  tryLink?: string;
  tryLinkLabel?: string;
};

const HeaderAccountButtons: React.FC<HeaderAccountButtonsProps> = ({
  isDark,
  user,
  tryLink,
  tryLinkLabel,
  loginLabel,
  loginLink,
  logoutLabel,
  logoutLink,
  profileLinks,
}) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const popupRef = useRef<HTMLDivElement>();
  const togglePopup = useCallback(() => setPopupOpen((oldValue) => !oldValue), []);
  const closePopup = useCallback(() => setPopupOpen(false), []);
  useClickOutside(popupRef, closePopup);

  return (
    <Container>
      <AccountPillContainer>
        <AccountPill
          label={user?.name ?? loginLabel}
          image={user?.image}
          showLabelOnMobile={!user}
          onClick={togglePopup}
          href={user ? undefined : loginLink}
          isDark={isDark}
        />
      </AccountPillContainer>
      {user && popupOpen && (
        <PopupLayout ref={popupRef} onClick={closePopup}>
          <PopupContainer>
            <ProfilePopup
              name={user.name}
              mail={user.email}
              image={user.image}
              premium={user.premium}
              isDark={isDark}
              links={profileLinks}
              logoutLabel={logoutLabel}
              logoutLink={logoutLink}
            />
          </PopupContainer>
        </PopupLayout>
      )}
      {!user?.hadTrial && !user?.premium && tryLink && tryLinkLabel && (
        <ButtonContainer>
          <Button href={tryLink} isSmall>
            {tryLinkLabel}
          </Button>
        </ButtonContainer>
      )}
    </Container>
  );
};

export default HeaderAccountButtons;
