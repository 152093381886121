import React, { ComponentProps, forwardRef } from 'react';
import { MenuItem as MenuItemType } from 'models/menu-item';
import { Container } from './sub-menu-item.style';

export type SubMenuItemProps = ComponentProps<'a'> &
  MenuItemType & {
    isActive?: boolean;
    href?: string;
  };

const SubMenuItem: React.FC<SubMenuItemProps> = forwardRef(({ isActive, href, onClick, label }, ref) => (
  <Container href={href} onClick={onClick} ref={ref} $isActive={isActive}>
    {label}
  </Container>
));

SubMenuItem.displayName = 'SubMenuItem';

export default SubMenuItem;
