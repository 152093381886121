import { LOCALES } from 'config/constants';
import React from 'react';
import NextLink from 'next/link';
import { MenuItem as MenuItemType } from 'models/menu-item';
import { MenuLinks } from 'data/menus';
import MenuItem from './menu-item';
import { Anchor, Close, Content, Header, List, ListItem, Logo, Nav, Title } from './navigation-menu.style';

export type NavigationMenuProps = {
  premium?: boolean;
  menuItems: MenuItemType[];
  locale: LOCALES;
  isTransparent?: boolean;
  isDark?: boolean;
  onClose?: () => void;
  activeUrl?: string;
  children?: React.ReactNode;
};

const NavigationMenu: React.FC<NavigationMenuProps> = ({
  premium,
  menuItems,
  locale,
  isTransparent,
  isDark,
  activeUrl,
  onClose,
  children,
}) => (
  <Nav $isTransparent={isTransparent} $isDark={isDark}>
    <Header>
      <NextLink legacyBehavior href={MenuLinks.ForYou} passHref>
        <Anchor>
          <Logo />
        </Anchor>
      </NextLink>
      <Title>Menu</Title>
      {onClose && <Close onClick={onClose} />}
    </Header>
    <Content>
      <List>
        {menuItems.map(
          ({
            label,
            url,
            activeOptions,
            icon,
            items,
            external,
            notPremiumAllowed,
            premiumAllowed,
            displayOnLocales,
          }) => {
            const shouldDisplayLocale = displayOnLocales ? displayOnLocales.includes(locale) : true;
            const shouldDisplayPremium = (premium && premiumAllowed) || (!premium && notPremiumAllowed);
            const shouldDisplay = shouldDisplayLocale && shouldDisplayPremium;

            return shouldDisplay ? (
              <ListItem key={label}>
                {external ? (
                  <MenuItem
                    locale={locale}
                    isDark={isDark}
                    isTransparent={isTransparent}
                    href={typeof url === 'object' ? url[locale] : url}
                    url={typeof url === 'object' ? url[locale] : url}
                    activeOptions={activeOptions}
                    label={label}
                    icon={icon}
                    activeUrl={activeUrl}
                    items={items}
                    target="_blank"
                  />
                ) : (
                  <NextLink legacyBehavior href={typeof url === 'object' ? url[locale] : url} passHref>
                    <MenuItem
                      locale={locale}
                      isDark={isDark}
                      isTransparent={isTransparent}
                      url={typeof url === 'object' ? url[locale] : url}
                      activeOptions={activeOptions}
                      label={label}
                      icon={icon}
                      activeUrl={activeUrl}
                      items={items}
                    />
                  </NextLink>
                )}
              </ListItem>
            ) : null;
          },
        )}
      </List>
      {children}
    </Content>
  </Nav>
);

export default NavigationMenu;
