import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { TransparentClose as CloseIcon, Logo as LogoIcon } from 'assets/icons';
import { breakpointAndUp, colors, typography } from 'assets/styles';

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: ${({ $isDark, $isTransparent }) => ($isDark || $isTransparent ? colors.white : colors.text)};
  background-color: ${({ $isDark }) => ($isDark ? colors.darkGrey : colors.white)};

  @media (${breakpointAndUp.tabletLarge}) {
    background-color: ${({ $isDark }) => ($isDark ? colors.darkGrey : colors.lightOrange)};

    ${({ $isTransparent }) =>
      $isTransparent &&
      css`
        background-color: ${rgba(colors.black, 0.5)};

        @supports ((-webkit-backdrop-filter: blur(3.6rem)) or (backdrop-filter: blur(3.6rem))) {
          background-color: ${rgba(colors.black, 0.4)};
          backdrop-filter: blur(3.6rem);
        }
      `}
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.8rem 1.2rem 1.8rem 1.8rem;

  @media (${breakpointAndUp.tabletLarge}) {
    padding: 3rem 2.6rem;
  }
`;

export const Anchor = styled.a`
  display: none;
  padding: 0;
  margin: 0;
  width: 100%;
  font-size: 2.2rem;
  color: inherit;
  text-decoration: none;

  @media (${breakpointAndUp.tabletLarge}) {
    display: block;
  }
`;

export const Logo = styled(LogoIcon)`
  width: 100%;
`;

export const Title = styled.h1`
  ${typography.h4};

  @media (${breakpointAndUp.tabletLarge}) {
    display: none;
  }
`;

export const Close = styled(CloseIcon)`
  color: inherit;
  font-size: 2rem;
  line-height: 62.5%;
  cursor: pointer;

  @media (${breakpointAndUp.tabletLarge}) {
    display: none;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
`;

export const List = styled.ul``;

export const ListItem = styled.li`
  margin: 1.6rem 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
