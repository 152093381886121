import styled from 'styled-components';
import { Logo as LogoIcon, Menu as MenuIcon } from 'assets/icons';
import { breakpointAndUp, colors } from 'assets/styles';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2.4rem;
  padding: 1.6rem 0;
  color: ${({ $isDark }) => ($isDark ? colors.white : colors.text)};
`;

export const Menu = styled(MenuIcon)`
  font-size: 2.4rem;
  margin-right: 1rem;
  cursor: pointer;
  display: none;

  @media (${breakpointAndUp.tablet}) {
    display: inline-block;
  }

  @media (${breakpointAndUp.tabletLarge}) {
    display: none;
  }
`;

export const Anchor = styled.a`
  color: inherit;
  text-decoration: none;
`;

export const Logo = styled(LogoIcon)`
  display: inline-block;
  font-size: 18rem;
  min-height: 2.4rem;
  height: 2.4rem;
`;
