import styled from 'styled-components';
import { rgba } from 'polished';
import { breakpointAndUp, colors, index, IndexOptions } from 'assets/styles';

export const Container = styled.div`
  position: relative;
  display: flex;
  min-width: 0;
`;

export const AccountPillContainer = styled.div`
  max-width: 25.6rem;
`;

export const ButtonContainer = styled.div`
  display: none;
  margin-left: 0.8rem;
  white-space: nowrap;

  @media (${breakpointAndUp.tablet}) {
    display: inline-block;
  }
`;

export const PopupLayout = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: ${index(IndexOptions.PROFILE_POPUP)};
  width: 100%;
  height: 100%;
  background-color: ${rgba(colors.black, 0.3)};

  @media (${breakpointAndUp.tablet}) {
    position: absolute;
    width: 34rem;
    height: auto;
    top: 4.2rem;
    right: 0;
    left: initial;
    background-color: transparent;
  }
`;

export const PopupContainer = styled.div`
  margin: 6.4rem 1.6rem;

  @media (${breakpointAndUp.tablet}) {
    margin: 0;
    max-width: 34rem;
  }
`;
