import React, { ComponentProps } from 'react';
import Head from 'next/head';
import { useIsDark } from 'domains/user';
import { colors } from 'assets/styles';

export type PageHeadProps = ComponentProps<'head'> & {
  title: string;
  description?: string;
  themeColor?: string;
};

const PageHead: React.FC<PageHeadProps> = ({ title, description, themeColor }) => {
  const isDark = useIsDark();

  return (
    <Head>
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
      <meta name="theme-color" content={themeColor || (isDark ? colors.darkGrey : colors.lightOrange)} />
    </Head>
  );
};

export default PageHead;
