import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { breakpointAndUp, colors, typography } from 'assets/styles';

export const Container = styled.div`
  margin: 7.2rem 0 2.4rem;
`;

export const Content = styled.div`
  max-width: 44.8rem;
  margin: 0 auto;

  text-align: center;
`;

export const Image = styled.span`
  ${typography.h5};
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.8rem;
  width: 7.2rem;
  height: 7.2rem;
  border-radius: 50%;

  background-color: ${({ $isDark }) => ($isDark ? rgba(colors.white, 0.1) : colors.lightOrange)};
  color: ${({ $isDark }) => ($isDark ? rgba(colors.white, 0.3) : colors.tint)};

  ${({ $src }) =>
    $src &&
    css`
      background-image: url('${$src}');
      background-size: cover;
      background-repeat: no-repeat;
    `}
`;

export const PremiumIconContainer = styled.span`
  position: absolute;
  right: -0.8rem;
  top: 0;
  font-size: 2.4rem;
  color: ${colors.tint};
`;

export const Name = styled.h2`
  ${typography.h2};
  margin-bottom: 0.4rem;

  color: ${({ $isDark }) => ($isDark ? colors.white : colors.text)};

  @media (${breakpointAndUp.tablet}) {
    margin-bottom: 0.8rem;
  }
`;

export const Mail = styled.span`
  ${typography.body};
  color: ${colors.greyTwo};
`;
