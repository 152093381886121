import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { colors, typography } from 'assets/styles';

export const Container = styled.div`
  padding: 2.4rem;
  border-radius: 0.8rem;
  background-color: ${({ $isDark }) => ($isDark ? colors.darkGrey : colors.white)};
  box-shadow: 0.4rem 0.4rem 3.6rem ${rgba(colors.black, 0.1)};
  color: ${({ $isDark }) => ($isDark ? colors.white : colors.text)};
  text-align: left;
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 1.6rem;
`;

export const Image = styled.span`
  ${typography.h5};
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.8rem;
  min-width: 5.6rem;
  min-height: 5.6rem;
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 50%;

  background-color: ${({ $isDark }) => ($isDark ? rgba(colors.white, 0.1) : colors.lightOrange)};
  color: ${({ $isDark }) => ($isDark ? rgba(colors.white, 0.3) : colors.tint)};

  ${({ $src }) =>
    $src &&
    css`
      background-image: url('${$src}');
      background-size: cover;
      background-repeat: no-repeat;
    `}
`;

export const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0;
`;

export const Name = styled.span`
  ${typography.h4};
  display: block;
  margin-bottom: 0.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Mail = styled.span`
  ${typography.bodySmall};
  display: block;
  color: ${colors.greyOne};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Links = styled.ul`
  border-top: 1px solid ${({ $isDark }) => ($isDark ? colors.dividerDM : colors.dividerLM)};
  padding-top: 1.6rem;
`;

export const Link = styled.li`
  padding-top: 2.4rem;
  color: ${({ $red }) => ($red ? colors.error : 'inherit')};

  &:first-child {
    padding-top: 0;
  }
`;

export const Anchor = styled.a`
  ${typography.h5};
  display: inline-flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
`;

export const IconContainer = styled.span`
  margin-right: 0.8rem;

  font-size: 2.4rem;
  line-height: 62.5%;
  color: inherit;
`;
