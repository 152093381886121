import React, { ComponentProps } from 'react';
import NextLink from 'next/link';
import { User } from 'models/user';
import { MenuItem } from 'models/menu-item';
import { MenuLinks } from 'data/menus';
import HeaderAccountButtons from 'components/header-account-buttons';
import { Container, Menu, Anchor, Logo } from './navigation-header.style';

export type NavigationHeaderProps = ComponentProps<'div'> & {
  onMenu?: () => void;
  user?: User;
  loginLabel: string;
  loginLink: string;
  logoutLabel: string;
  logoutLink: string;
  profileLinks: MenuItem[];
  tryLink?: string;
  tryLinkLabel?: string;
  isDark?: boolean;
};

const NavigationHeader: React.FC<NavigationHeaderProps> = ({
  onMenu = () => {},
  user,
  isDark,
  loginLabel,
  loginLink,
  logoutLabel,
  logoutLink,
  profileLinks,
  tryLink,
  tryLinkLabel,
}) => (
  <Container $isDark={isDark}>
    <div>
      <Menu onClick={onMenu} />
      <NextLink legacyBehavior href={MenuLinks.ForYou} passHref>
        <Anchor>
          <Logo />
        </Anchor>
      </NextLink>
    </div>
    <HeaderAccountButtons
      user={user}
      isDark={isDark}
      loginLabel={loginLabel}
      loginLink={loginLink}
      logoutLabel={logoutLabel}
      logoutLink={logoutLink}
      profileLinks={profileLinks}
      tryLinkLabel={tryLinkLabel}
      tryLink={tryLink}
    />
  </Container>
);

export default NavigationHeader;
