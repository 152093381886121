import { LOCALES } from 'config/constants';
import { SharePlatforms } from 'models/share-platforms';

// pages of which the url is provided by the app-info API
export enum MenuItemPage {
  Subscription = 'subscription',
  Subscribe = 'subscribe',
}

export type MenuItem = {
  icon?: JSX.Element;
  label: string;
  page?: MenuItemPage;
  url?: string | Record<LOCALES, string>;
  activeOptions?: string[];
  items?: MenuItem[];
  premiumAllowed?: boolean;
  notPremiumAllowed?: boolean;
  displayOnLocales?: LOCALES[];
  external?: boolean;
};

export type ShareMenuItem = {
  type: SharePlatforms;
  icon?: JSX.Element;
  animationIcon?: JSX.Element;
  label: string;
  animationLabel?: string;
  generateLink?: (url: string, title?: string) => string;
  generateOnClick?: (url: string, title?: string) => () => void;
};
