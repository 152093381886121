import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { breakpointAndUp, colors, index, IndexOptions } from 'assets/styles';
import { imageToBackgroundImageCss } from 'utils/parse-image';

export const Container = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${({ $isDark, $hasGradient, $backgroundColor }) =>
    $hasGradient ? $backgroundColor : $isDark ? colors.text : colors.white};

  @media (${breakpointAndUp.tabletLarge}) {
    flex-direction: row;
  }

  ${({ $image, $hasGradient }) =>
    $image &&
    !$hasGradient &&
    css`
      ${imageToBackgroundImageCss($image)};
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: ${rgba(colors.black, 0.1)};
      }
    `}
`;

export const ThemeOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ $backgroundColor }) => rgba($backgroundColor, 0.6)};

  @media (${breakpointAndUp.tablet}) {
    background-color: ${({ $backgroundColor }) => rgba($backgroundColor, 0.8)};

    @supports ((-webkit-backdrop-filter: blur(6rem)) or (backdrop-filter: blur(6rem))) {
      background-color: ${({ $backgroundColor }) => rgba($backgroundColor, 0.6)};
      backdrop-filter: blur(6rem);
    }
  }
`;

export const NavigationContainer = styled.div`
  position: relative;
  width: 100%;

  @media (${breakpointAndUp.tabletLarge}) {
    width: 19.2rem;
  }

  @media (${breakpointAndUp.desktop}) {
    width: 22.4rem;
  }
`;

export const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
`;

export const Content = styled.div`
  flex: 1;
  width: 100%;
  padding-bottom: ${({ $hasBottomSpacing, $hasMusicSpacing }) => {
    const spacing = [2.4, $hasBottomSpacing ? 8 : 0, $hasMusicSpacing ? 8.6 : 0].reduce(
      (result, entry) => result + entry,
      0,
    );

    return `calc(${spacing}rem + env(safe-area-inset-bottom))`;
  }};

  @media (${breakpointAndUp.tablet}) {
    padding-bottom: ${({ $hasMusicSpacing }) => ($hasMusicSpacing ? 16.8 : 7.6)}rem;
  }

  @media (${breakpointAndUp.tabletLarge}) {
    padding: 1.6rem 0 ${({ $hasMusicSpacing }) => ($hasMusicSpacing ? 17.2 : 8)}rem;
  }

  @media (${breakpointAndUp.desktop}) {
    padding: 1.6rem 0 ${({ $hasMusicSpacing }) => ($hasMusicSpacing ? 16.8 : 7.6)}rem;
  }
`;

export const MobileNavigationContainer = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${index(IndexOptions.NAVIGATION)};

  @media (${breakpointAndUp.tablet}) {
    display: none;
  }
`;
