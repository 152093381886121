import { MenuItem } from 'models/menu-item';
import {
  forYou,
  forYouPremium,
  meditation,
  music,
  programs,
  flow,
  yoga,
  favorites,
  favoriteMeditations,
  favoriteMusic,
  recentMeditations,
  recentMusic,
  profile,
  profileSubscriptionPremium,
  profileSubscription,
  profileRecently,
  profileLanguage,
  profileTimezone,
  profileGiftVoucher,
} from './menu-items';
export { MenuLinks } from './links';

export const mobileMenuItems: MenuItem[] = [forYou, forYouPremium, meditation, music, programs, favorites];

export const desktopMenuItems: MenuItem[] = [forYou, forYouPremium, meditation, music, flow, yoga, programs, favorites];

export const favoriteTabs: MenuItem[] = [favoriteMeditations, favoriteMusic];

export const recentlyPlayedTabs: MenuItem[] = [recentMeditations, recentMusic];

export const profilePopupLinks: MenuItem[] = [profile, profileSubscriptionPremium, profileSubscription];

export const profileLinks: MenuItem[] = [profileRecently, profileLanguage, profileTimezone, profileGiftVoucher];
