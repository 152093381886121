import React, { ComponentProps, forwardRef } from 'react';
import NextLink from 'next/link';
import { MenuItem as MenuItemType } from 'models/menu-item';
import isPartOfUrl from 'utils/is-part-of-url';
import SubMenuItem from '../sub-menu-item';
import { Container, Item, IconContainer, Label, List, ListItem } from './menu-item.style';

type MenuItemProps = ComponentProps<'a'> & {
  icon: JSX.Element;
  label: string;
  locale: string;
  url: string;
  activeOptions?: string[];
  items: MenuItemType[];
  href?: string;
  activeUrl?: string;
  isDark?: boolean;
  isTransparent?: boolean;
};

const MenuItem: React.FC<MenuItemProps> = forwardRef(
  (
    { icon, label, href, onClick, items, locale, url, activeOptions, activeUrl, isDark, isTransparent, ...rest },
    ref,
  ) => {
    const isActive = isPartOfUrl(activeOptions || url, activeUrl);

    return (
      <Container>
        <Item {...rest} href={href} onClick={onClick} ref={ref} $isActive={isActive}>
          {icon && <IconContainer>{icon}</IconContainer>}
          <Label>{label}</Label>
        </Item>
        {items && (
          <List $isOpen={isActive} $isDark={isDark} $isTransparent={isTransparent}>
            {items.map((child) => (
              <ListItem key={child.label}>
                <NextLink legacyBehavior href={typeof child.url === 'object' ? child.url[locale] : child.url} passHref>
                  <SubMenuItem
                    url={typeof child.url === 'object' ? child.url[locale] : child.url}
                    label={child.label}
                    icon={child.icon}
                    isActive={isPartOfUrl(typeof child.url === 'object' ? child.url[locale] : child.url, activeUrl)}
                  />
                </NextLink>
              </ListItem>
            ))}
          </List>
        )}
      </Container>
    );
  },
);

MenuItem.displayName = 'MenuItem';

export default MenuItem;
