import styled, { css } from 'styled-components';
import { colors, typography } from 'assets/styles';

const activeStyle = css`
  color: ${colors.tint};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0.2rem;
    background-color: ${colors.tint};
  }
`;

export const Container = styled.a`
  ${typography.h5};
  position: relative;
  display: block;
  padding: 0.8rem 0.8rem 0.8rem 1.6rem;

  text-decoration: none;
  text-align: left;
  color: inherit;

  ${({ $isActive }) => $isActive && activeStyle};

  &:focus {
    ${activeStyle};
  }

  @media (any-hover: hover) {
    &:hover {
      ${activeStyle};
    }
  }
`;
