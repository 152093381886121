import React, { ComponentProps } from 'react';
import NextLink from 'next/link';
import { PremiumIconContainer } from 'layouts/profile-header/profile-header.style';
import { Image as ImageType } from 'models/image';
import { MenuItem } from 'models/menu-item';
import { LogOut, PremiumBadge } from 'assets/icons';
import {
  Container,
  Header,
  Image,
  UserDetails,
  Name,
  Mail,
  Links,
  Link,
  Anchor,
  IconContainer,
} from './profile-popup.style';

export type ProfilePopupProps = ComponentProps<'div'> & {
  name: string;
  mail: string;
  image?: ImageType;
  links: MenuItem[];
  logoutLink: string;
  logoutLabel: string;
  premium?: boolean;
  isDark?: boolean;
};

const ProfilePopup: React.FC<ProfilePopupProps> = ({
  name,
  mail,
  image,
  links,
  premium,
  logoutLink,
  logoutLabel,
  isDark,
}) => {
  const initials = name
    .split(' ')
    .map((name) => name.charAt(0))
    .join('');

  return (
    <Container $isDark={isDark}>
      <Header>
        <Image $src={image?.src} $isDark={isDark}>
          {premium && (
            <PremiumIconContainer>
              <PremiumBadge />
            </PremiumIconContainer>
          )}
          {!image ? initials : null}
        </Image>
        <UserDetails>
          <Name>{name}</Name>
          <Mail>{mail}</Mail>
        </UserDetails>
      </Header>
      <Links $isDark={isDark}>
        {links.map(({ icon, label, url, page, external, premiumAllowed, notPremiumAllowed }) =>
          (premium && premiumAllowed) || (!premium && notPremiumAllowed) ? (
            <Link key={url ?? page}>
              {external ? (
                <Anchor href={url} target="_blank">
                  <IconContainer>{icon}</IconContainer>
                  {label}
                </Anchor>
              ) : (
                <NextLink legacyBehavior href={url as string} passHref>
                  <Anchor>
                    <IconContainer>{icon}</IconContainer>
                    {label}
                  </Anchor>
                </NextLink>
              )}
            </Link>
          ) : null,
        )}
        <Link $red>
          <Anchor href={logoutLink}>
            <IconContainer>
              <LogOut />
            </IconContainer>
            {logoutLabel}
          </Anchor>
        </Link>
      </Links>
    </Container>
  );
};

export default ProfilePopup;
