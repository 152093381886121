import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { LOCALES } from 'config/constants';
import { MenuItem as MenuItemType, MenuItemPage } from 'models/menu-item';
import { useArgumentsLink } from 'domains/route';
import { useIntl } from 'domains/intl';
import { Selectors as userSelectors } from 'domains/user';
import { useAppInfo } from 'logic-domains/app';
import { profilePopupLinks } from 'data/menus';
import Grid from 'components/grid';
import NavigationHeader from 'components/navigation-header';
import NavigationMenu from 'components/navigation-menu';
import { HeaderContainer, NavigationContainer, MenuContainer } from './navigation.style';

export type NavigationProps = {
  menuItems: MenuItemType[];
  activeUrl: string;
  isDark?: boolean;
  isTransparent?: boolean;
  hasTabletHeader?: boolean;
};

const Navigation: React.FC<NavigationProps> = ({ menuItems, activeUrl, isDark, isTransparent, hasTabletHeader }) => {
  const { locale } = useRouter();
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState<boolean>(false);
  const user = useSelector(userSelectors.getUser);
  const [
    {
      headerCtaBtn,
      headerCtaTargetUrl,
      headerCtaTrialBtn,
      headerCtaTrialTargetUrl,
      commonLoginBtn,
      commonLoginTargetUrl,
      commonLogoutBtn,
      commonLogoutTargetUrl,
      commonManageSubscriptionUrl,
      commonSubscribeTargetUrl,
    },
  ] = useAppInfo();
  const loginLink = useArgumentsLink(commonLoginTargetUrl, true);
  const logoutLink = useArgumentsLink(commonLogoutTargetUrl, true);
  const headerLink = useArgumentsLink(headerCtaTargetUrl, true);
  const headerTrialLink = useArgumentsLink(headerCtaTrialTargetUrl, true);
  const openMenu = useCallback(() => setOpen(true), [setOpen]);
  const closeMenu = useCallback(() => setOpen(false), [setOpen]);
  const profileLinks = useMemo(
    () =>
      profilePopupLinks.map((menuItem) => {
        let url: string | Record<LOCALES, string>;
        switch (menuItem.page) {
          case MenuItemPage.Subscription:
            url = commonManageSubscriptionUrl;
            break;
          case MenuItemPage.Subscribe:
            url = commonSubscribeTargetUrl;
            break;
          default:
            url = menuItem.url;
        }

        return { ...menuItem, label: formatMessage(menuItem.label), url };
      }),
    [commonManageSubscriptionUrl, commonSubscribeTargetUrl, formatMessage],
  );

  return (
    <>
      <HeaderContainer $hasTabletHeader={hasTabletHeader}>
        <Grid columns="mb:1">
          <NavigationHeader
            onMenu={openMenu}
            user={user}
            loginLabel={commonLoginBtn}
            loginLink={loginLink}
            logoutLabel={commonLogoutBtn}
            logoutLink={logoutLink}
            profileLinks={profileLinks}
            tryLinkLabel={user?.hadTrial ? headerCtaBtn : headerCtaTrialBtn}
            tryLink={user?.hadTrial ? headerLink : headerTrialLink}
            isDark={isDark}
          />
        </Grid>
      </HeaderContainer>
      <NavigationContainer $isOpen={open} onClick={closeMenu}>
        <MenuContainer $isOpen={open}>
          <NavigationMenu
            premium={!!user} // in this case we misuse the premium prop on purpose
            locale={locale as LOCALES}
            menuItems={menuItems}
            activeUrl={activeUrl}
            isDark={isDark}
            isTransparent={isTransparent}
            onClose={closeMenu}
          />
        </MenuContainer>
      </NavigationContainer>
    </>
  );
};

export default Navigation;
